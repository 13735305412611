/* eslint-disable */
import { i18n } from '@/locales/i18n';
import { GenericModel } from '@/shared/model/generic-model';
import IdField from '@/shared/fields/id-field';
import StringField from '@/shared/fields/string-field';
import IntegerField from '@/shared/fields/integer-field';
// import DecimalField from '@/shared/fields/decimal-field';
import JsonField from '@/shared/fields/json-field';
import DateTimeField from '@/shared/fields/date-time-field';
// import DateTimeRangeField from '@/shared/fields/date-time-range-field';

function label(name) {
  return i18n(`settings.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),

//#region [ General Settings ]
  email: new StringField('email', label('email')),
  phoneNumber: new StringField('phoneNumber', label('phoneNumber')),
  whatsapp: new StringField('whatsapp', label('whatsapp')),
  address: new StringField('address', label('address')),

  instagram: new StringField('instagram', label('instagram')),
  snapchat: new StringField('snapchat', label('snapchat')),
  facebook: new StringField('facebook', label('facebook')),
  twitter: new StringField('twitter', label('twitter')),
  linkedin: new StringField('linkedin', label('linkedin')),
  pinterest: new StringField('pinterest', label('pinterest')),
  tiktok: new StringField('tiktok', label('tiktok')),
  youtube: new StringField('youtube', label('youtube')),
  snapchat: new StringField('snapchat', label('snapchat')),
//#endregion

  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdBy: new StringField('createdBy', label('createdBy')),
  updatedBy: new StringField('updatedBy', label('updatedBy')),
  // createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class SettingModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
