<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        <app-i18n code="settings.title"></app-i18n>
      </h2>
    </div>
    <!-- BEGIN: Settings Tabs -->
    <div class="intro-y box px-5 pt-5 mt-5">
      <div class="nav nav-tabs flex-col sm:flex-row justify-center lg:justify-start" role="tablist">
        <a
          id="general-tab"
          data-toggle="tab"
          data-target="#general"
          href="javascript:;"
          class="py-4 sm:mr-8"
          :class="tab == 'general' ? 'active' : ''"
          role="tab"
          aria-controls="general"
          aria-selected="true"
          @click="tab = 'general'"
        >
          {{ i18n('settings.tabs.general') }}
        </a>
        <a
          id="termsAndConditions-tab"
          data-toggle="tab"
          data-target="#termsAndConditions"
          href="javascript:;"
          class="py-4 sm:mr-8"
          :class="tab == 'termsAndConditions' ? 'active' : ''"
          role="tab"
          aria-selected="false"
          @click="tab = 'termsAndConditions'"
        >
          {{ i18n('settings.tabs.termsAndConditions') }}
        </a>
      </div>
    </div>
    <!-- END:  Settings Tabs -->
    <div class="intro-y tab-content mt-5">
      <settings-general-tab v-if="tab == 'general'" />
      <div id="termsAndConditions" class="tab-pane active" role="tabpanel" aria-labelledby="termsAndConditions-tab" v-if="tab == 'termsAndConditions'">
        <settings-terms-and-conditions-tab />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import SettingsTabGeneral from '@/views/settings/settings-tab-general.vue'
import SettingsTabTermsAndConditions from '@/views/settings/settings-tab-terms-and-conditions.vue'

export default defineComponent({
  components: {
    [SettingsTabGeneral.name]: SettingsTabGeneral,
    [SettingsTabTermsAndConditions.name]: SettingsTabTermsAndConditions
  },
  data() {
    return {
      tab: 'general'
      // tab: 'shipping'
    }
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  created() {
    document.title = this.i18n('app.title') + ' | ' + this.i18n('routes.settings')
  },
  watch: {
    isRTL() {
      document.title = this.i18n('app.title') + ' | ' + this.i18n('routes.settings')
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>
